import styled from 'styled-components';
import { Table } from 'discover.medical.react-component-library';

export const StyledCell = styled(Table.Cell)`
  padding: 0.75rem 0 !important;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &&:nth-child(odd) {
    font-weight:${({ theme }) => theme.font.weight.semibold};
  }
`;
