import React, { useEffect, useState } from 'react';
import { Tools } from 'discover.medical.react-component-library';
import PropTypes from 'prop-types';
import { StyledType } from './index.styled';

const FastFeedbackWidget = ({
  label,
  amplitude,
  results,
  searchTerm,
  resultSetId,
  searchDuration,
  thumbsUpStatus,
  thumbsDownStatus,
  onThumbsUpCallback,
  onThumbsDownCallback,
}) => {
  const [isThumbsUp, setIsThumbsUp] = useState(thumbsUpStatus);
  const [isThumbsDown, setIsThumbsDown] = useState(thumbsDownStatus);

  const logAmplitudeEvent = (feedback) => {
    amplitude.logEvent({
      feedback,
      results,
      search_term: searchTerm,
      result_set_id: resultSetId,
      search_duration: searchDuration,
    });
  };

  const onThumbsUp = () => {
    setIsThumbsUp(true);
    setIsThumbsDown(false);
    logAmplitudeEvent('positive');
    onThumbsUpCallback();
  };

  const onThumbsDown = () => {
    setIsThumbsUp(false);
    setIsThumbsDown(true);
    logAmplitudeEvent('negative');
    onThumbsDownCallback();
  };

  useEffect(() => {
    setIsThumbsUp(thumbsUpStatus);
  }, [thumbsUpStatus]);

  useEffect(() => {
    setIsThumbsDown(thumbsDownStatus);
  }, [thumbsDownStatus]);

  return (
    <div data-testid="fast-feedback-widget-container">
      <StyledType element="label" display="inline">
        {label}
      </StyledType>
      <Tools>
        <Tools.Item
          data-testid="fast-feedback-widget-thumbs-up"
          data-auto={isThumbsUp ? 'thumb_up_filled' : 'thumb_up_outlined'}
          glyph={isThumbsUp ? 'thumb_up' : 'thumb_up_outline'}
          onClick={() => !isThumbsUp && onThumbsUp()}
        >
          Thumbs Up
        </Tools.Item>
        <Tools.Item
          data-testid="fast-feedback-widget-thumbs-down"
          data-auto={isThumbsDown ? 'thumb_down_filled' : 'thumb_down_outlined'}
          glyph={isThumbsDown ? 'thumb_down' : 'thumb_down_outline'}
          onClick={() => !isThumbsDown && onThumbsDown()}
        >
          Thumbs Down
        </Tools.Item>
      </Tools>
    </div>
  );
};

FastFeedbackWidget.propTypes = {
  label: PropTypes.string,
  amplitude: PropTypes.shape,
  results: PropTypes.arrayOf(PropTypes.string),
  searchTerm: PropTypes.string,
  resultSetId: PropTypes.string,
  searchDuration: PropTypes.string,
  thumbsUpStatus: PropTypes.bool,
  thumbsDownStatus: PropTypes.bool,
  onThumbsUpCallback: PropTypes.func,
  onThumbsDownCallback: PropTypes.func,
};

FastFeedbackWidget.defaultProps = {
  label: 'Were these results helpful?',
  amplitude: {
    logEvent: () => {},
  },
  results: [],
  searchTerm: '',
  resultSetId: '',
  searchDuration: '',
  thumbsUpStatus: false,
  thumbsDownStatus: false,
  onThumbsUpCallback: () => {},
  onThumbsDownCallback: () => {},
};

FastFeedbackWidget.displayName = 'FastFeedbackWidget';

export default FastFeedbackWidget;
