import { imageStrategy } from './imageStrategy';
import { ajaxStrategy } from './ajaxStrategy';
import { browserStrategy } from './browserStrategy';
import { cookieStrategy } from './cookieStrategy';

export const pingStrategies = {
  image: imageStrategy,
  ajax: ajaxStrategy,
  cookie: cookieStrategy,
  browser: browserStrategy,
};
