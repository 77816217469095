import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Type,
  Divider,
} from 'discover.medical.react-component-library';
import axios from 'axios';
import PropTypes from 'prop-types';
import HttpResourceValidationRow from './http_resource/HttpResourceValidationRow';
import { StyledCell } from './index.styled';
import { NOT_AVAILABLE, COOKIE_URL, DSD_COOKIE_URL } from './constants';

const initialUrls = [
  {
    domain: 'www.cloudflare.com',
    pingUrl: 'https://www.cloudflare.com/cdn-cgi/trace',
    strategy: 'ajax',
  },
];
const SystemCheck = ({
  userDetails,
  urls,
  isDsd,
  onRun,
  onReset
}) => {
  const [clientIp, setClientIp] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [isRunTestExecuted, setRunTestExecuted] = useState(false);
  const whiteListUrls = urls.concat(initialUrls);

  const convertToJsonData = (res) => {
    try {
      let traceData = res.data
        .replace(/[\r\n\t]+/g, '","')
        .replace(/\=+/g, '":"'); // eslint-disable-line  no-useless-escape
      traceData = `{"${traceData.slice(0, traceData.lastIndexOf('","'))}"}`;

      return JSON.parse(traceData);
    } catch (error) {
      console.error('error while json parsing :', error);
    }
    return {};
  };
  const fetchClientIp = () => {
    axios.get('https://www.cloudflare.com/cdn-cgi/trace').then((res) => {
      const traceData = convertToJsonData(res);
      setClientIp(traceData.ip);
      setUserAgent(traceData.uag);
    });
  };
  const handleRunTest = () => {
    setRunTestExecuted(true);
    onRun();
  };
  const handleResetTest = () => {
    setRunTestExecuted(false);
    onReset();
  };
  useEffect(() => {
    fetchClientIp();
  }, []);

  const cookieUrl = isDsd ? DSD_COOKIE_URL : COOKIE_URL;

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <StyledCell colSpan={4}>
              <Type element="h4" color="senary">
                Metadata
              </Type>
            </StyledCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <StyledCell>Test Date</StyledCell>
            <StyledCell>{new Date().toUTCString()}</StyledCell>
            <StyledCell>Auth Type</StyledCell>
            <StyledCell>
              {userDetails.trafficSource
                ? userDetails.trafficSource.authMethodPrimary
                : NOT_AVAILABLE}
            </StyledCell>
          </Table.Row>
          <Table.Row>
            <StyledCell>IP Address</StyledCell>
            <StyledCell data-testid="client-ip">{clientIp}</StyledCell>
            <StyledCell>User ID</StyledCell>
            <StyledCell>
              {userDetails.puaId ? userDetails.puaId : NOT_AVAILABLE}
            </StyledCell>
          </Table.Row>
          <Table.Row>
            <StyledCell>Email Address</StyledCell>
            <StyledCell>
              {userDetails.email ? userDetails.email : NOT_AVAILABLE}
            </StyledCell>
            <StyledCell>AAPIDR</StyledCell>
            <StyledCell>
              {userDetails.aapidr || NOT_AVAILABLE}
            </StyledCell>
          </Table.Row>
          <Table.Row>
            <StyledCell>Customer Name</StyledCell>
            <StyledCell>
              {userDetails.affiliationName
                ? userDetails.affiliationName
                : NOT_AVAILABLE}
            </StyledCell>
            <StyledCell>Profile ID</StyledCell>
            <StyledCell>{userDetails.profileId}</StyledCell>
          </Table.Row>
          <Table.Row>
            <StyledCell>Browser/User Agent</StyledCell>
            <StyledCell data-testid="uag" colSpan={3}>
              {userAgent}
            </StyledCell>
          </Table.Row>
          <Table.Row>
            <StyledCell>Health Status</StyledCell>
            <StyledCell colSpan={3}>
              <span>
                <a
                  href="https://status.ebsco.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Click Here
                </a>
                &nbsp;to View EBSCO Health Status Page
              </span>
            </StyledCell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Divider color="secondary" thickness="lg" />
      <Table>
        <Table.Header>
          <Table.Row>
            <StyledCell colSpan={2} align="left">
              <Button
                disabled={isRunTestExecuted}
                data-auto="run-test"
                variant="secondary"
                borderRadius="sm"
                onClick={handleRunTest}
              >
                Run Test
              </Button>
              &nbsp;
              <Button
                disabled={!isRunTestExecuted}
                data-auto="reset-test"
                variant="secondary"
                borderRadius="sm"
                onClick={handleResetTest}
              >
                Reset
              </Button>
            </StyledCell>
          </Table.Row>
        </Table.Header>
        <Table.Header>
          <Table.Row>
            <StyledCell align="left">
              <Type element="h4" color="secondary">
                Cookie
              </Type>
            </StyledCell>
            <StyledCell align="left">
              <Type element="h4" color="secondary">
                Status
              </Type>
            </StyledCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <HttpResourceValidationRow
            key="Session"
            domain="Session"
            url={cookieUrl}
            strategy="cookie"
            isRunTestExecuted={isRunTestExecuted}
          />
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <StyledCell align="left">
              <Type element="h4" color="secondary">
                URL
              </Type>
            </StyledCell>
            <StyledCell align="left">
              <Type element="h4" color="secondary">
                Status
              </Type>
            </StyledCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {whiteListUrls.map((url) => (
            <HttpResourceValidationRow
              key={url.domain}
              domain={url.domain}
              url={url.pingUrl}
              strategy={url.strategy}
              isRunTestExecuted={isRunTestExecuted}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

SystemCheck.propTypes = {
  userDetails: {
    puaId: PropTypes.string,
    email: PropTypes.string,
    profileId: PropTypes.string,
    affiliationName: PropTypes.string,
    trafficSource: {
      authMethodPrimary: PropTypes.string,
    },
  },
  urls: PropTypes.arrayOf({
    domain: PropTypes.string,
    pingUrl: PropTypes.string,
    strategy: PropTypes.string,
  }),
  isDsd: PropTypes.bool,
  onRun: PropTypes.func,
  onReset: PropTypes.func,
};
SystemCheck.defaultProps = {
  userDetails: {
    puaId: '',
    email: '',
    profileId: '',
    affiliationName: '',
    trafficSource: {
      authMethodPrimary: '',
    },
  },
  urls: [],
  isDsd: false,
  onRun: () => {},
  onReset: () => {},
};

SystemCheck.displayName = 'SystemCheck';
export default SystemCheck;
