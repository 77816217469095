import axios from 'axios';

export const ajaxStrategy = {
  apply(url, { setStatusChecking, setStatusValidated, setStatusFailed }) {
    setStatusChecking();
    axios
      .get(url)
      .then(() => {
        setStatusValidated();
      })
      .catch((err) => {
        if (err.response) {
          setStatusValidated();
        } else {
          setStatusFailed();
        }
      });
  },
};
