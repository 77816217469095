/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Input,
  InputText,
  Field,
  Toast,
} from 'discover.medical.react-component-library';
import PropTypes from 'prop-types';
import {
  PHIContent,
  SubmitFeedback,
  SubmitButton,
  FormFieldContainer,
  ErrorMessage,
  FeedbackWidgetForm,
  LabelContainer,
  PrimaryLabel,
  SubLabel,
  StyledSpinner,
} from './index.styled';

const FeedbackForm = React.forwardRef(
  (
    {
      formik,
      closeFeedbackToast,
      isFeedbackSubmitted,
      isSuccess,
      isIE,
      isFailure,
      closeFeedbackFailureToast,
    },
    ref
  ) => (
    <FeedbackWidgetForm data-auto="feedback-form">
      <div ref={ref}>
        <FormFieldContainer isIE={isIE}>
          {isFeedbackSubmitted && <StyledSpinner size="sm" />}
          <Field id="comments" label="Feedback">
            <PHIContent>
              We welcome your constructive professional feedback. Please ensure no Protected Health
              Information (PHI) is included.
            </PHIContent>
            <Input.Textarea
              rows={7}
              maxLength={1000}
              onChange={formik.handleChange}
              value={formik.values.comments}
              name="comments"
              data-auto="feedback-comment"
            />
          </Field>
          {formik.errors.comments && formik.touched.comments && (
            <ErrorMessage>{formik.errors.comments}</ErrorMessage>
          )}
        </FormFieldContainer>
        <FormFieldContainer>
          <Field id="email" label="Email">
            <InputText
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              data-auto="email"
            />
          </Field>
          {formik.errors.email && formik.touched.email && (
            <ErrorMessage>{formik.errors.email}</ErrorMessage>
          )}
        </FormFieldContainer>
        <FormFieldContainer>
          <Field id="fullName">
            <LabelContainer>
              <PrimaryLabel id="fullNameLabel">Full Name</PrimaryLabel>
              <SubLabel>(Optional)</SubLabel>
            </LabelContainer>
            <InputText
              onChange={formik.handleChange}
              value={formik.values.fullName}
              name="fullName"
              data-auto="full-name"
            />
          </Field>
        </FormFieldContainer>
        <FormFieldContainer>
          <Field id="role">
            <LabelContainer>
              <PrimaryLabel id="titleLabel">Title/Role</PrimaryLabel>
              <SubLabel>(Optional)</SubLabel>
            </LabelContainer>
            <InputText
              onChange={formik.handleChange}
              value={formik.values.role}
              name="role"
              data-auto="role"
            />
          </Field>
        </FormFieldContainer>
        <FormFieldContainer>
          <Field id="organization">
            <LabelContainer>
              <PrimaryLabel id="organizationLabel">Organization</PrimaryLabel>
              <SubLabel>(Optional)</SubLabel>
            </LabelContainer>
            <InputText
              onChange={formik.handleChange}
              value={formik.values.organization}
              name="organization"
              data-auto="organization"
            />
          </Field>
        </FormFieldContainer>
        <SubmitFeedback>
          <SubmitButton
            type="submit"
            variant="primary"
            fullWidth
            onClick={formik.handleSubmit}
            data-auto="submit-btn"
          >
            Submit Feedback
          </SubmitButton>
        </SubmitFeedback>
        <Toast
          data-auto="feedback-success"
          isOpen={isSuccess}
          onClose={closeFeedbackToast}
          autohideTimeout={3000}
        >
          Feedback submitted successfully
        </Toast>
        <Toast
          data-auto="toolbar-error-toast"
          isOpen={isFailure}
          onClose={closeFeedbackFailureToast}
          autohideTimeout={3000}
          type="error"
        >
          Unexpected error occurred. Please refresh and try again.
        </Toast>
      </div>
    </FeedbackWidgetForm>
  )
);

FeedbackForm.propTypes = {
  formik: PropTypes.shape({
    values: {
      fullName: PropTypes.string,
      email: PropTypes.string,
      comments: PropTypes.string,
    },
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    touched: PropTypes.object.isRequired,
  }).isRequired,
  closeFeedbackToast: PropTypes.func.isRequired,
  isFeedbackSubmitted: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isIE: PropTypes.bool.isRequired,
  isFailure: PropTypes.bool.isRequired,
  closeFeedbackFailureToast: PropTypes.func.isRequired,
};
FeedbackForm.defaultProps = {};

FeedbackForm.displayName = 'FeedbackForm';
export default FeedbackForm;
