import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from 'discover.medical.react-component-library';
import { StyledCell } from '../index.styled';
import { pingStrategies } from '../strategy/pingStrategies';
import {
  STATUS_VALIDATED,
  STATUS_ICON_GREEN,
  STATUS_FAILED,
  STATUS_ICON_RED,
  STATUS_CHECKING
} from '../constants';
import InfoDialog from '../dialog/index';

const HttpResourceValidationRow = (props) => {
  const {
    url, domain, isRunTestExecuted
  } = props;
  let { strategy } = props;
  const [status, setStatus] = useState(null);
  const [statusIcon, setStatusIcon] = useState({});
  const [isBrowserStatus, setIsBrowserStatus] = useState(false);
  const [browserUrl, setBrowserUrl] = useState(null);
  const setStatusValidated = () => {
    setStatus(STATUS_VALIDATED);
    setStatusIcon(STATUS_ICON_GREEN);
  };
  const setStatusFailed = () => {
    setStatus(STATUS_FAILED);
    setStatusIcon(STATUS_ICON_RED);
  };
  const setStatusChecking = () => {
    setStatus(STATUS_CHECKING);
  };
  const setBrowserStatus = (pingUrl) => {
    setIsBrowserStatus(true);
    setBrowserUrl(pingUrl);
  };

  const setResourceStatuses = {
    setStatusChecking,
    setStatusFailed,
    setStatusValidated,
    setBrowserStatus,
  };

  useEffect(() => {
    if (isRunTestExecuted) {
      try {
        /* DE63135 - Fix for IE 10 */
        if (document.documentMode <= 10) {
          strategy = 'ajax';
        }
        pingStrategies[strategy].apply(url, setResourceStatuses);
      } catch (err) {
        setStatusFailed();
      }
    } else {
      setStatus(null);
      setStatusIcon({});
      setIsBrowserStatus(false);
      setBrowserUrl(null);
    }
  }, [isRunTestExecuted, strategy]);

  return (
    <Table.Row>
      <StyledCell data-testid="whitelist-url">
        {domain}
      </StyledCell>
      <StyledCell data-testid={domain}>
        <Icon icon={statusIcon} />
        {' '}
        {status}
        {isBrowserStatus && <InfoDialog browserUrl={browserUrl} />}
      </StyledCell>
    </Table.Row>
  );
};

HttpResourceValidationRow.propTypes = {
  url: PropTypes.string,
  domain: PropTypes.string,
  strategy: PropTypes.string,
  isRunTestExecuted: PropTypes.bool
};

HttpResourceValidationRow.defaultProps = {
  url: '',
  domain: '',
  strategy: '',
  isRunTestExecuted: false
};

HttpResourceValidationRow.displayName = 'HttpResourceValidationRow';
export default HttpResourceValidationRow;
