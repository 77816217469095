import styled from 'styled-components';
import { Button, Spinner } from 'discover.medical.react-component-library';

// prettier-ignore
export const FeedbackWidgetForm = styled.div`
  background-color: ${({ theme }) => theme.colors.quinary.base};
  padding: 0 1rem 1rem;
  box-shadow: 0 0px 10px ${({ theme }) => theme.colors.info};
  border-top-left-radius: 2rem;
  max-width: 22rem;
  line-height: 1rem;
  display: flex;
  flex-direction: column-reverse;
  height:38.25rem; overflow-y: auto;

  @media (max-height: ${({ theme }) => theme.breakpoints.sm}) {
  height:25.25rem; overflow-y: auto;
  }
`;
export const FormFieldContainer = styled.div`
  margin-top: ${({ isIE }) => (isIE ? 0 : '0.625rem')};
`;

export const SubmitButton = styled(Button)`
  padding: ${({ theme }) => theme.space.md};
  margin-top: ${({ theme }) => theme.space.xl};
`;

export const PHIContent = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  padding-bottom: ${({ theme }) => theme.space.sm};
  width: 100%;
  margin-top: -0.3rem;
`;
export const SubmitFeedback = styled.div`
  margin: -1rem;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.red.base};
  font-size: ${({ theme }) => theme.font.size.xs};
`;

export const LabelContainer = styled.div`
  display: block;
  margin-bottom: 0.5rem;
`;
export const PrimaryLabel = styled.span`
  margin-top: ${({ theme }) => theme.space.lg};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.space.sm};
  color: ${({ theme }) => theme.colors.gray800};
`;
export const SubLabel = styled.span`
  font-style: italic;
  padding-left: 0.3125rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray700};
`;

export const StyledSpinner = styled(Spinner)`
  margin: -1.5rem 0;
`;
