import axios from 'axios';

export const cookieStrategy = {
  apply(url, { setStatusChecking, setStatusValidated, setStatusFailed }) {
    setStatusChecking();
    axios
      .get(url)
      .then((resp) => {
        if (resp && resp.data && resp.data.hasCookies === true) {
          setStatusValidated();
        } else {
          setStatusFailed();
        }
      })
      .catch(() => {
        setStatusFailed();
      });
  },
};
