import styled from 'styled-components';
import { Tray } from 'discover.medical.react-component-library';

export const FeedbackWidgetContainer = styled.div`
  bottom: -0.18rem;
  right: 2rem;
  ${({ feedbackButtonConfig }) => `position: ${feedbackButtonConfig.position};`}
  z-index: 10;
`;

export const FeedbackTray = styled(Tray)`
  margin-right: -1.9rem;
  ${({ isFeedbackButtonFixed, dialogConfig }) => !isFeedbackButtonFixed && `z-index: ${dialogConfig.zIndex};
  position: absolute;
  right: ${dialogConfig.right};
  bottom: ${dialogConfig.bottom};`}
`;

export const MinimizedFeedbackForm = styled.div`
  background-color: ${({ theme }) => theme.colors.quinary.base};
  padding: 2rem;
  border-top-left-radius: 2rem;
  min-width: 22rem;
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.size.xl};
  font-family: ${({ theme }) => theme.font.family.secondary};
  color: ${({ theme }) => theme.colors.primary.base};
  box-shadow: 0 0px 10px ${({ theme }) => theme.colors.info};
  margin-right: -1.9rem;
  ${({ isFeedbackButtonFixed, dialogConfig }) => !isFeedbackButtonFixed && `z-index: ${dialogConfig.zIndex};
  position: absolute;
  right: ${dialogConfig.right};
  bottom: ${dialogConfig.bottom};`}
`;

export const MinimizedFeedbackHeader = styled.div`
  margin-top: -1rem;
`;
