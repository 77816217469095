import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'discover.medical.react-component-library';
import { ButtonContainer } from './index.styled';

const FeedbackWidgetButtons = ({
  isExpanded,
  toggleExpandButton,
  handleTrayClose,
  isFeedbackButtonFixed,
  dialogConfig,
}) => (
  <ButtonContainer
    isFeedbackButtonFixed={isFeedbackButtonFixed}
    dialogConfig={dialogConfig}
  >
    <Button
      kind="text"
      padded={false}
      size="iconOnly"
      onClick={toggleExpandButton}
      data-auto={isExpanded ? 'expand-more' : 'expand-less'}
    >
      <Icon
        icon={{
          glyph: isExpanded ? 'expand_more' : 'expand_less',
          size: 'md',
          color: 'link',
        }}
      />
    </Button>
    <Button
      kind="text"
      padded={false}
      size="iconOnly"
      onClick={handleTrayClose}
      data-auto="close-button"
    >
      <Icon icon={{ glyph: 'close', size: 'sm', color: 'link' }} />
    </Button>
  </ButtonContainer>
);

FeedbackWidgetButtons.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  toggleExpandButton: PropTypes.func.isRequired,
  handleTrayClose: PropTypes.func.isRequired,
  isFeedbackButtonFixed: PropTypes.bool,
  dialogConfig: PropTypes.shape({ buttonsRight: PropTypes.string }),
};

FeedbackWidgetButtons.defaultProps = {
  isFeedbackButtonFixed: false,
  dialogConfig: {
    buttonsRight: '0.5rem',
  },
};

FeedbackWidgetButtons.displayName = 'FeedbackForm';
export default FeedbackWidgetButtons;
