export const imageStrategy = {
  apply(url, { setStatusChecking, setStatusValidated, setStatusFailed }) {
    setStatusChecking();
    const img = new Image();
    img.onload = () => {
      setStatusValidated();
    };
    img.onerror = () => {
      setStatusFailed();
    };
    img.src = url;
  },
};
