import React, { useState, useRef, useEffect } from 'react';
import { Icon, Button } from 'discover.medical.react-component-library';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import axios from 'axios';
import FeedbackForm from './feedback-form/FeedbackForm';
import FeedbackWidgetButtons from './feedback-widget-buttons/FeedbackWidgetButtons';

import {
  FeedbackWidgetContainer,
  FeedbackTray,
  MinimizedFeedbackForm,
  MinimizedFeedbackHeader,
} from './index.styled';

const initialValues = {
  fullName: '',
  email: '',
  comments: '',
};
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  comments: Yup.string().required('Comment is required'),
});
const FeedbackWidget = ({
  feedbackUrl,
  onSubmitFeedbackSuccess,
  onSubmitFeedbackFailure,
  productType,
  feedbackButtonConfig,
  dialogConfig,
  isMobile,
  profileInfo,
}) => {
  const pageLink = typeof window !== 'undefined' ? window.location.href : '';
  const isFeedbackButtonFixed = feedbackButtonConfig.position === 'fixed';
  const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const feedbackRef = useRef(null);

  const resetFeedbackToast = async () => {
    setIsFeedbackSubmitted(false);
    setIsSuccess(false);
  };

  const handleSubmitFeedbackFailure = () => {
    setIsFailed(true);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsFeedbackSubmitted(true);
      const {
        fullName, email, comments, organization, role
      } = values;

      const payload = {
        pageLink,
        fullName,
        email,
        comments,
        productType,
        organization,
        role,
        profileInfo,
      };
      try {
        await axios.post(feedbackUrl, payload);
        onSubmitFeedbackSuccess();
        setIsSuccess(true);
      } catch (e) {
        onSubmitFeedbackFailure();
        handleSubmitFeedbackFailure();
        setIsSuccess(false);
      }
      setIsFeedbackSubmitted(false);
      setSubmitting(false);
    },
  });
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpandButton = () => {
    setExpanded(!isExpanded);
  };
  const [isTrayOpen, setTrayOpen] = useState(false);

  const toggleTrayState = () => {
    setTrayOpen(!isTrayOpen);
  };

  useEffect(() => {
    if ((isTrayOpen || isExpanded) && feedbackRef && feedbackRef.current) {
      feedbackRef.current.scrollIntoView();
    }
  }, [isTrayOpen, isExpanded]);

  const handleTrayClose = () => {
    setTrayOpen(false);
    setExpanded(true);
    formik.resetForm();
  };

  const closeFeedbackToast = () => {
    resetFeedbackToast();
    formik.resetForm();
    setTrayOpen(false);
  };

  const closeFeedbackFailureToast = () => {
    setIsFailed(false);
  };

  const checkIsIE = () => /edge|msie\s|trident\//i.test(navigator.userAgent);

  return (
    <FeedbackWidgetContainer
      feedbackButtonConfig={feedbackButtonConfig}
    >
      <div>
        {isExpanded ? (
          <FeedbackTray
            toggle={isTrayOpen}
            data-auto="feedback-tray"
            isFeedbackButtonFixed={isFeedbackButtonFixed}
            dialogConfig={dialogConfig}
            className="feedback-tray"
          >
            {isTrayOpen && (
              <>
                <FeedbackForm
                  ref={feedbackRef}
                  formik={formik}
                  closeFeedbackToast={closeFeedbackToast}
                  isFeedbackSubmitted={isFeedbackSubmitted}
                  isSuccess={isSuccess}
                  isIE={checkIsIE()}
                  isFailure={isFailed}
                  closeFeedbackFailureToast={closeFeedbackFailureToast}
                />
                <FeedbackWidgetButtons
                  isExpanded={isExpanded}
                  toggleExpandButton={toggleExpandButton}
                  handleTrayClose={handleTrayClose}
                  isFeedbackButtonFixed={isFeedbackButtonFixed}
                  dialogConfig={dialogConfig}
                />
              </>
            )}
          </FeedbackTray>
        ) : (
          <div data-auto="feedback-minimized-view">
            <MinimizedFeedbackForm
              isFeedbackButtonFixed={isFeedbackButtonFixed}
              dialogConfig={dialogConfig}
            >
              <MinimizedFeedbackHeader>Feedback</MinimizedFeedbackHeader>
              <FeedbackWidgetButtons
                isExpanded={isExpanded}
                toggleExpandButton={toggleExpandButton}
                handleTrayClose={handleTrayClose}
                isFeedbackButtonFixed={isFeedbackButtonFixed}
                dialogConfig={dialogConfig}
              />
            </MinimizedFeedbackForm>
          </div>
        )}
        {!isTrayOpen && (
          <Button
            variant="primary"
            size={isMobile ? 'sm' : 'md'}
            data-auto="feedback-button"
            id="feedback_button"
            onClick={toggleTrayState}
          >
            <Icon icon={{ glyph: 'comment', size: isMobile ? 'sm' : 'md' }} />
            {isMobile ? <></> : <span>Feedback</span>}
          </Button>
        )}
      </div>
    </FeedbackWidgetContainer>
  );
};

FeedbackWidget.propTypes = {
  feedbackUrl: PropTypes.string.isRequired,
  onSubmitFeedbackSuccess: PropTypes.func,
  onSubmitFeedbackFailure: PropTypes.func,
  productType: PropTypes.string.isRequired,
  feedbackButtonConfig: PropTypes.shape({ position: PropTypes.string }),
  dialogConfig: PropTypes.shape({
    right: PropTypes.string,
    buttonsRight: PropTypes.string,
    bottom: PropTypes.string,
    zIndex: PropTypes.number,
  }),
  isMobile: PropTypes.bool,
  profileInfo: PropTypes.shape({
    customerId: PropTypes.string,
    userId: PropTypes.string,
    authenticationType: PropTypes.string,
  }),
};

FeedbackWidget.defaultProps = {
  feedbackButtonConfig: {
    position: 'static',
  },
  dialogConfig: {
    right: '2rem',
    buttonsRight: '1rem',
    bottom: '0',
    zIndex: 1,
  },
  isMobile: false,
  onSubmitFeedbackSuccess: () => {},
  onSubmitFeedbackFailure: () => {},
  profileInfo: {
    customerId: 'NA',
    userId: 'NA',
    authenticationType: 'unauthenticated',
  },
};

FeedbackWidget.displayName = 'FeedbackWidget';
export default FeedbackWidget;
