import React from 'react';

import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Type
} from 'discover.medical.react-component-library';
import PropTypes from 'prop-types';

const InfoDialog = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { browserUrl } = props;
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Button variant="secondary" borderRadius="sm" onClick={openModal}>
        Validate
      </Button>
      <Dialog
        aria-labelledby="text-dialog-title"
        isOpen={isOpen}
        maxWidth="sm"
        onRequestClose={closeModal}
      >
        <DialogTitle>
          <Type id="text-dialog-title" element="h3">
            Sorry, we were unable to validate the connectivity with this domain
            automatically due to the CORS policy.
          </Type>
        </DialogTitle>

        <Card borderYColor="gray300" borderYStyle="solid" borderYWidth="1px">
          <CardContent>
            <Type element="p">
              <span>
                Please&nbsp;
                <a href={browserUrl} target="_blank" rel="noreferrer">
                  click here
                </a>
                &nbsp;to verify the connection manually.
              </span>
            </Type>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

InfoDialog.propTypes = {
  browserUrl: PropTypes.string,
};

InfoDialog.defaultProps = {
  browserUrl: '',
};

InfoDialog.displayName = 'InfoDialog';

export default InfoDialog;
